import {
    ImageContainer as SourceImageContainer,
} from 'SourceComponent/Image/Image.container';

/** @namespace Theme/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static defaultProps = {
        ...super.defaultProps,
        loading: 'lazy',
    };

    containerProps() {
        const {
            loading,
        } = this.props;

        return {
            ...super.containerProps(),
            loading,
        };
    }
}

export default ImageContainer;
