import { getDateValue } from 'Util/Form/Extract';

export const MILLISECONDS_PER_MINUTE = 60000;

/** @namespace Theme/Util/Manipulations/Date/convertStringToDate */
export const convertStringToDate = (stringDate, options) => {
    const defaultOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const date = new Date((stringDate || '').toString().replace(/-/g, '/'));
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return date.toLocaleDateString(language, options || defaultOptions);
};

/** @namespace Theme/Util/Manipulations/Date/convertToTwoDigitDate */
export const convertToTwoDigitDate = (stringDate, options) => {
    const defaultOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const date = new Date((stringDate || '').toString().replace(/-/g, '/'));
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return date.toLocaleDateString(language, options || defaultOptions);
};

/** @namespace Theme/Util/Manipulations/Date/getTimeInCurrentTimezone */
export const getTimeInCurrentTimezone = (timestamp) => {
    const currentDate = new Date();
    const timezone = currentDate.getTimezoneOffset() * MILLISECONDS_PER_MINUTE;
    const timeInCurrentTimezone = new Date(timestamp.replace(/-/g, '/')) - new Date(timezone);

    return getDateValue(timeInCurrentTimezone);
};
