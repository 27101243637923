import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/Link/Link.container';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Theme/Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    getTo() {
        const { to: toProp } = this.props;
        // fix null, undefined and empty links
        const to = toProp || '/';

        if (typeof to === 'string') {
            // in case this URL is absolute, do not append store
            if (/^(https?|mailto|tel):/.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        }

        const pathname = to.pathname || '/';

        return {
            ...to,
            pathname: appendWithStoreCode(pathname),
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
