/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */

import { DomElement } from 'html-react-parser';

import {
    HIGH_PRIORITY_ELEMENTS,
    HtmlComponent as SourceHtmlComponent,
} from 'SourceComponent/Html/Html.component';

export {
    HIGH_PRIORITY_ELEMENTS,
};

/** @namespace Theme/Component/Html/Component */
export class HtmlComponent extends SourceHtmlComponent {
    parserOptions = {
        replace: (domNode: DomElement) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return <></>;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        },
    };
}

export default HtmlComponent;
