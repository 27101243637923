export const parser = new DOMParser();

export const chevronRegex = /<|>/g;

/** @namespace Theme/Util/Format/Index/chevronReplacer */
export const chevronReplacer = (match) => (match === '<' ? '&lt;' : '&gt;');

/** @namespace Theme/Util/Format/Index/___ */
export const ___ = (text) => {
    if (typeof text !== 'string') {
        return text;
    }
    const preparedText = text.replace(chevronRegex, chevronReplacer);
    const dom = parser.parseFromString(`<!doctype html><body>${ preparedText }`, 'text/html');

    return dom.body.textContent;
};

/** @namespace Theme/Util/Format/Index/quoteAsOrder */
export const quoteAsOrder = (quote) => {
    const order = {
        can_reorder: false,
        carrier: '',
        id: quote.name,
        increment_id: '',
        order_date: quote.created_at,
        credit_memos: [],
        invoices: [],
        shipments: [],
        payment_methods: [{ name: __('Selected at Checkout') }],
        rss_link: '',
        shipping_address: {
            ...quote.shipping_addresses[0],
            region: quote.shipping_addresses[0]?.region?.label || '',
            region_id: '',
            country_id: quote.shipping_addresses[0]?.country?.code || '',
            middlename: '',
            vat_id: '',
        },
        billing_address: {
            ...quote.billing_address,
            region: quote.billing_address?.region?.label || '',
            region_id: '',
            country_id: quote.billing_address?.country?.code || '',
            middlename: '',
            vat_id: '',
        },
        shipping_method: '',
        status: quote.status,
        intel_meta: { win_design_num: '' },
        comments: quote.comments,
    };

    order.items = quote.items.map((quoteItem) => ({
        quantity_ordered: quoteItem.quantity,
        quantity_shipped: 0,
        quantity_refunded: 0,
        quantity_canceled: 0,
        entered_options: [],
        selected_options: [],
        row_subtotal: {
            value: quoteItem.prices.row_total.value,
            currency: quoteItem.prices.row_total.currency,
        },
        product_name: quoteItem.product.name,
        product_url_key: '',
        product_sku: '',
        product_sale_price: {
            value: 0,
            currency: 'USD',
        },
    }));

    const total_shipping = quote.prices.grand_total.value - quote.prices.subtotal_including_tax.value;

    const total_tax = quote.prices.applied_taxes.reduce((result, item) => (
        result + Number(item?.amount?.value || 0)
    ), 0);

    order.total = {
        grand_total: quote.prices.grand_total,
        base_grand_total: quote.prices.grand_total,
        subtotal: quote.prices.subtotal_excluding_tax,
        discounts: [],
        taxes: quote.prices.applied_taxes,
        total_shipping: {
            value: total_shipping,
            currency: 'USD',
        },
        total_tax: {
            value: total_tax,
            currency: 'USD',
        },
        shipping_handling: {
            total_amount: {
                value: total_shipping,
                currency: 'USD',
            },
        },
    };

    return order;
};
